import React from 'react'
import "../style/components/values.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Pagination } from "swiper";
import FadeInSection from './utility/FadeInSection';

const Values = (props: { headline: string, values: { icon: string; headline: string; text: string; }[], showLines: boolean }) => {

  const isBrowser = typeof window !== "undefined";
  const isMobile: MediaQueryList | null = isBrowser ? window.matchMedia('(min-width: 0px) and (max-width: 576px)') : null;

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="custom-swiper-bullet ' + className + '">' + "</span>";
    },
  };


  return (
    <section id="values">
      <div className='wrapper'>
        {props.showLines && <hr className='horizontal-line' />}
        <FadeInSection>
          <h2>{props.headline}</h2>
          {isMobile && !isMobile.matches && <div className='values-slider'>
            <div className='values-card-container swiper-wrapper'>
              {props.values.map((value, i) =>
                <div key={`services-${i}`} className="values-card swiper-slide">
                  <img className='values-card-image' src={value.icon} alt={value.alt} />
                  <h3 className='values-card-headline'>{value.headline}</h3>
                  <p>{value.text}</p>
                </div>
              )
              }
            </div>
          </div>}
          {isMobile && isMobile.matches &&
            <Swiper pagination={pagination} modules={[Pagination]} className="custom-swiper">
              {props.values.map((value, i) =>
                <SwiperSlide key={`services-${i}`} className="values-card swiper-slide">
                  <img className='values-card-image' src={value.icon} alt={value.alt} />
                  <h3 className='values-card-headline'>{value.headline}</h3>
                  <p>{value.text}</p>
                </SwiperSlide>
              )
              }
            </Swiper>
          }
        </FadeInSection>
      </div>
    </section>
  )
}

export default Values