import React from 'react'
import Cad from '../components/Cad'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import History from '../components/History'
import Footer from '../components/utility/Footer'
import Values from '../components/Values'
import Why from '../components/Why'
import heroImage from "../images/haus-01.jpg";
import houseIcon from "../images/facade.svg";
import sunIcon from "../images/sun.svg";
import sustainabilityIcon from "../images/sustainability.svg";
import messungImage from "../images/messung.png";
import Navbar from '../components/utility/Navbar'
import { Helmet } from 'react-helmet'

const heroContent = {
  image: heroImage,
  alt: "Ein mehrstöckiges Holzhaus mit großem Garten",
  headline: "Wir über uns",
  text: "Früher sagte man einfach: Der Kunde ist König. Das ist auch heute noch richtig. Genauer gesagt: Unser Ziel ist eine vetrauensvolle Zusammenarbeit und eine partnerschaftliche Beziehung zu unseren. Ihre Zufriedenheit ist unser erstes Ziel. Dafür arbeiten wir ehrlich, zielstrebig und innovativ."
}

const valuesData = [
  {
    icon: houseIcon,
    alt: "Ein Haus mit Fassade",
    headline: "Grünes Zuhause",
    text: "Wir sind als Betrieb leider noch nicht bei 100% CO2-Neutralität - aber Sie können mit einem Holzhaus einiges für unser Klima tun und dann auch noch ein hervorragendes Raumklima genießen."
  },
  {
    icon: sunIcon,
    alt: "Eine aufgehende Sonne",
    headline: "Klimatisierte Häuser",
    text: "...erreichen wir nicht durch eine Klimaanlage sondern intelligente Planung, sommerlichen Hitzeschutz, effiziente Speichermasse, exzellente Dämmung und hochwertige Haustechnik."
  },
  {
    icon: sustainabilityIcon,
    alt: "Es geht aufwärts",
    headline: "Nachhaltige Entwicklung",
    text: "Nachhaltigkeit - ein großes Wort. Im modernen Holzbau steckt dafür besonderes Potential. So lassen sich heute alle Bauteile extrem diffusionsoffen konstruieren. Und das schafft nicht nur ein gutes Raumklima sondern ist die beste Voraussetzung für extrem langlebige Holzbauten."
  }
]

const whyData = {
  isLeft: false,
  image: messungImage,
  alt: "Ein Mensch bei der Ausmessung einer Baustelle",
  headline: "3D Tachymeter",
  text: "Saubere Planung ist kein Selbstzweck sondern lohnt sich am Ende für alle Beteiligten, das ist unsere Überzeugung. Um die Geometrie im Bestand sauber zu erfassen arbeiten wir mit eigener 3D-Laser-Vermessung und natürlich 3D-CAD.",
  list: [
    {
      iconIsRising: true,
      text: "Aufmaß vor Ort und gleichzeitige Analyse durch den Fachingenieur."
    },
    {
      iconIsRising: true,
      text: "Auswertung am CAD, Abstimmung mit Technik und Baurecht, Umsetzung in detaillierte Holzbauplanung."
    },
    {
      iconIsRising: false,
      text: "Vorfertigung mit einfacher aber durchdachter Technik in unserer Zimmerei."
    },
    {
      iconIsRising: true,
      text: "Montage und Regendichtheit innerhalb weniger Tage."
    }
  ]
}

const AboutPage = () => {
  return (
    <>
      <Helmet title="Zimmerei Müller - Über Uns">
        <meta property="og:title" content="Zimmerei Müller - Über Uns" />
        <meta
          property="og:description"
          content="Früher sagte man einfach: Der Kunde ist König. Das ist auch heute noch richtig. Genauer gesagt: Unser Ziel ist eine vetrauensvolle Zusammenarbeit und eine partnerschaftliche Beziehung zwischen unseren Kunden und uns. Ihre Zufriedenheit ist unser erstes Ziel. Dafür arbeiten wir ehrlich, zielstrebig und innovativ.."
        />
        <meta property="og:url" content="https://holzbaumueller.de/ueber-uns" />
        <meta
          name="description"
          content="Früher sagte man einfach: Der Kunde ist König. Das ist auch heute noch richtig. Genauer gesagt: Unser Ziel ist eine vetrauensvolle Zusammenarbeit und eine partnerschaftliche Beziehung zwischen unseren Kunden und uns. Ihre Zufriedenheit ist unser erstes Ziel. Dafür arbeiten wir ehrlich, zielstrebig und innovativ.."
        />
      </Helmet>
      <Navbar />
      <main>
        <Hero headline={heroContent.headline} text={heroContent.text} image={heroContent.image} alt={heroContent.alt} />
        <History />
        <Cad />
        <Why className='why-list-wrapper' alt={whyData.alt} backgroundColorClass='white' isImageLeft={whyData.isLeft} image={whyData.image} headline={whyData.headline} text={whyData.text} list={whyData.list} />
        <Values headline="Ökologische Baumaterialien" values={valuesData} showLines={false} />
        <Contact />
      </main>
      <Footer />
    </>
  )
}

export default AboutPage