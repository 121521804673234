import React from 'react';
import listIcon from "../images/listbullet.svg";
import "../style/components/why.scss";
import FadeInSection from './utility/FadeInSection';

const Why = (props: { headline: string, text: string, image: string, alt: string, isImageLeft: boolean, list: { iconIsRising: boolean; text: string; }[], backgroundColorClass?: string, className?: string }) => {

  return (
    <section id="why" className={`${props.backgroundColorClass ? props.backgroundColorClass : "lightgray"}`}>
      <FadeInSection>
        <div className={`wrapper content-container ${props.isImageLeft ? 'left' : 'right'}`}>
          <h2 className={`why-headline  ${props.isImageLeft ? 'left' : 'right'}`} dangerouslySetInnerHTML={{ __html: props.headline }}></h2>
          <p className={`why-text  ${props.isImageLeft ? 'left' : 'right'}`}>{props.text}</p>
        </div>
      </FadeInSection>
      <div className={`why-list-container ${props.isImageLeft ? 'left' : 'right'}`}>
        <div className='lines-wrapper'>
          <img className={`why-image ${props.isImageLeft ? 'left' : 'right'}`} src={props.image} alt={props.alt} />
          <hr className={`line-tangente ${props.isImageLeft ? 'left' : 'right'}`} />
        </div>
        <FadeInSection className={props.className}>
          <div className={`why-list ${props.isImageLeft ? 'left' : 'right'}`}>
            {props.list.map((listItem, i) =>
              <div key={`${listItem.text}-${i}`} className='why-list-item'>
                <img src={listIcon} className={listItem.iconIsRising ? "rising" : "falling"} alt="Ein Bindestrich" />
                <span className='why-list-item-text'>{listItem.text}</span>
              </div>)
            }
          </div>
        </FadeInSection>
      </div>
    </section>
  )
}

export default Why