import React from 'react'
import nineteentwentyImage from "../images/1920.jpg";
import nineteenfivftyImage from "../images/1950.jpg";
import nineteensixtyImage from "../images/1960.jpg";
import nineteenseventyImage from "../images/1970.jpg";
import twentyzeroImage from "../images/2000.jpg";
import twentytwentyImage from "../images/2020.png";
import "../style/components/history.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import FadeInSection from './utility/FadeInSection';

const timelineData = [
  {
    image: nineteentwentyImage,
    headline: "um 1900",
    text: "Der Vater von Johann Müller baut als Maurer und Zimmerer bereits Wohnhäuser und landwirtschaftliche Gebäude im Welzheimer Wald.",
    alt: "Ein spitzes Dach aus Holz"
  },
  {
    image: nineteenfivftyImage,
    headline: "1949",
    text: "Nach der Heimkehr aus dem Zweiten Weltkrieg baut Johann Müller die Werkstatt im Hüttenbühl als Zimmermeister wieder auf.",
    alt: "Der Meisterbrief von Johann Müller"
  },
  {
    image: nineteensixtyImage,
    headline: "1963",
    text: "Johann Müller hat sich in der Gartenstraße in Kaisersbach eine Schreinerei und Zimmerei, zuerst im Souterrain des Wohnhauses, dann in einer extra Werkstatt errichtet und baut vorwiegend Treppen und Dachstühle.",
    alt: "Ein Wohnhaus mit Werkstatt"
  },
  {
    image: nineteenseventyImage,
    headline: "1972",
    text: "Walter Müller übernimmt das Geschäft von seinem Vater Johann und errichtet eine große Abbundhalle, eine Lagerhalle, Garagen, Lagerplatz und einen Bürotrakt. Eines seiner ersten Großprojekte ist der Neubau Schullandheim Mönchhof zusammen mit Zimmermeister Hans Abele aus Ebni.",
    alt: "Ein Holzdach eines Restaurants"
  },
  {
    image: twentyzeroImage,
    headline: "2002",
    text: "Mit dem Abschluss des Studiums als Bauingenieur arbeitet Reinhardt Müller mit an einer kontinuierlichen Entwicklung zu einem modernen Holzbaubetrieb.",
    alt: "Ein Bild einer Häusersiedlung mit Holzrahmen"
  },
  {
    image: twentytwentyImage,
    headline: "2016",
    text: "Reinhardt und Melitta Müller übernehmen als Gesellschafter die Firma mit allen Gebäuden und Mitarbeitern als Zimmerei Müller GmbH.",
    alt: "Zimmerei Müller"
  }
]

const breakpoints: any = {
  // when window width is >= 640px
  768: {
    slidesPerView: 2.75,
    spaceBetween: 40
  }
}

const History = () => {

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="custom-swiper-bullet ' + className + '">' + "</span>";
    }
  };

  return (
    <section id="history">
      <div className='wrapper'>
        <FadeInSection>
          <h2>100 Jahre<br />Zimmerei Müller</h2>
          <p className='history-text'>Bereits seit über einem Jahrhundert schreiben wir als Zimmerei Müller im Welzheimer Wald Holzbaugeschichte. So lange besteht unser Handwerksbetrieb, der sich mit dem enormen technischen Fortschritt kontinuierlich weiterentwickelt hat. Obwohl wir immer noch zu den kleineren im Ländle gehören, sind wir gleichzeitig ein moderner Holzbaubetrieb und erfahrener Komplettanbieter insbesondere bei Wohnhäusern.</p>
        </FadeInSection>
        <FadeInSection>
          <div className='timeline-container'>
            <Swiper slidesPerView={1} spaceBetween={40} breakpoints={breakpoints} pagination={pagination} modules={[Pagination]} className="custom-swiper">
              {
                timelineData.map((time, i) =>
                  <SwiperSlide key={`services-${i}`} className="time-card swiper-slide">
                    <img className='time-image' src={time.image} alt={time.alt} loading='lazy' />
                    <h3 className='time-headline'>{time.headline}</h3>
                    <p className='time-text'>{time.text}</p>
                  </SwiperSlide>
                )
              }
            </Swiper>

          </div>
        </FadeInSection>
      </div>
    </section>
  )
}

export default History