import React from 'react'
import cadImage from "../images/cad.png";
import "../style/components/cad.scss"
import FadeInSection from './utility/FadeInSection';

const Cad = () => {
  return (
    <section id="cad">
      <FadeInSection>
        <div className='wrapper'>
          <h2>3D CAD / CAM</h2>
          <p>Für uns der Weg schlechthin für effizientes Arbeiten, genaue Absprachen, eine richtig gute Arbeitsvorbereitung, die optimale Vorfertigung im Neubau oder Altbau. Und Sie bekommen schon im voraus eine 3D-Visualisierung als normale HTML-3D-Datei.</p>
        </div>
      </FadeInSection>
      <div className='lines-wrapper'>
        <img className='cad-image' src={cadImage} alt="ein 3D-Model eines Holzhauses" />
        <hr className='horizontal-line' />
        <hr className='tangente-line' />
      </div>
    </section>
  )
}

export default Cad